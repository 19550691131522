<!--  -->
<template>
  <div class="main_box">
    <div class="nav_menu">
      <div class="menu_left">
        <el-input
          class="search_input"
          placeholder="请输入闸门名称"
          v-model="value"
        ></el-input>
        <el-button type="primary" @click="handleSearch(0)">查询</el-button>
        <el-button type="info" @click="handleSearch(1)">重置</el-button>
      </div>
      <div class="menu_right">
        <el-button type="success" @click="handleGate('add')"
          >新增闸门</el-button
        >
      </div>
    </div>
    <div>
      <el-table
        v-loading="loading"
        element-loading-text="数据量过大，加载中..."
        :header-cell-style="{
          'background-color': '#4073b6',
          color: '#FFFFFF'
        }"
        :data="tableData"
        stripe
        style="width: 100%"
      >
        <el-table-column type="index" prop="sid" label="序号" width="95">
        </el-table-column>
        <el-table-column prop="siteName" label="站点名称"> </el-table-column>
        <el-table-column prop="gateName" label="闸门名称" show-overflow-tooltip>
        </el-table-column>
        <el-table-column prop="gateHeightLimit" label="闸门限高">
        </el-table-column>
        <el-table-column prop="deviceAddress" label="设备地址码">
        </el-table-column>
        <el-table-column width="250" label="操作">
          <template slot-scope="scope">
            <el-button class="edit" @click="handleGate('edit', scope.row)"
              >编辑</el-button
            >
            <el-button class="delete" @click="handleDelete(scope.row)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        background
        :hide-on-single-page="true"
        layout="total, prev, pager, next, jumper"
        :total="pagination.total"
        @current-change="handleCurrent"
        :current-page="pagination.page"
      >
      </el-pagination>
    </div>
    <el-dialog
      class="border-r-8 role-dialog"
      :visible.sync="addShow"
      width="calc(388/1920*100vw)"
      @close="closeBox"
    >
      <div slot="title" class="dialogTitle t-x font-s-18">{{ title }}</div>
      <div class="contentAdd" style="margin-bottom: 10px">
        <el-form :model="gateInfo" ref="ruleForm" :rules="rules">
          <el-form-item prop="siteId" style="margin-top:calc(15/1080*100vh)">
            <span class="label_r">站点名称</span>
            <el-select
              v-model="gateInfo.siteId"
              placeholder="请选择"
              @change="changeValue"
              readonly
            >
              <el-option
                v-for="item in stationList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>

          <el-form-item prop="gateName" style="margin-top:calc(30/1080*100vh)">
            <span class="label_r">闸门名称</span>
            <el-input
              placeholder="请输入闸门名称"
              maxlength="20"
              v-model="gateInfo.gateName"
            ></el-input>
          </el-form-item>

          <el-form-item
            prop="gateHeightLimit"
            style="margin-top:calc(30/1080*100vh)"
          >
            <span class="label_r">闸门限高</span>
            <el-input
              placeholder="请输入闸门限高"
              v-model="gateInfo.gateHeightLimit"
              maxlength="4"
            ></el-input>
          </el-form-item>
          <el-form-item
            prop="deviceAddress"
            class="mgb"
            style="margin-top:calc(30/1080*100vh)"
          >
            <span class="label_r">设备地址码</span>
            <el-input
              placeholder="请输入设备地址码"
              v-model="gateInfo.deviceAddress"
              maxlength="20"
              :readonly="true"
            ></el-input>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="resetForm('ruleForm')" class="guanBi"
          >关闭</el-button
        >
        <el-button
          @click="handleSave('ruleForm')"
          type="primary"
          style="margin-left:calc(30/1920*100vw)"
          >保存</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { gateAdd, gateUpdate } from "../../../api/index";
export default {
  components: {},
  data() {
    const isNum = (rule, value, callback) => {
      const age = /^[0-9]*$/;
      if (!age.test(value)) {
        callback(new Error("闸门限高只能为数字"));
      } else {
        callback();
      }
    };
    return {
      tableData: [],
      loading: false,
      value: "", //搜索内容
      pagination: {
        // 分页
        size: 10,
        page: 1,
        total: 0
      },
      stationList: [], // 站点列表
      gateInfo: {}, // 闸门信息
      addShow: false,
      title: "", // 弹框标题
      type: false,
      ruleForm: {},
      rules: {
        siteId: [
          { required: true, message: "请选择所属站点", trigger: "blur" }
        ],
        gateName: [
          { required: true, message: "请输入闸门名称", trigger: "blur" }
        ],
        gateHeightLimit: [
          { required: true, message: "请输入闸门限高", trigger: "blur" },
          { validator: isNum, trigger: "blur" }
        ],
        num: [{ required: true, message: "请输入闸孔", trigger: "change" }]
      }
    };
  },
  computed: {},
  watch: {},
  methods: {
    // 搜索重置
    handleSearch(type) {
      // 重置
      if (type) {
        this.value = "";
        this.pagination.page = 1;
        this.getList();
      } else {
        // 搜索
        let { page, size } = this.pagination;
        this.$get(`/gate/page/${page}/${size}`, {
          gateName: this.value
        }).then(res => {
          this.tableData = res.data.list;
          console.log(this.tableData);
          this.pagination.total = res.data.total;
        });
      }
    },
    // 列表
    getList() {
      // /gate/page/{page}/{size}
      this.loading = true;
      let { page, size } = this.pagination;
      this.$get(`/gate/page/${page}/${size}`).then(res => {
        this.tableData = res.data.list;
        this.pagination.total = res.data.total;
        this.loading = false;
      });
      if (this.stationList.length == 0) {
        // 站点菜单查询
        this.$get(`/site/page`, {
          page: 1,
          size: 100
        }).then(res => {
          this.gateInfo.siteId = res.data.list[0].id;

          this.stationList = res.data.list.map(item => {
            let obj = {
              value: item.id,
              label: item.siteName,
              deviceAddress: item.deviceAddress
            };
            return obj;
          });
        });
      }
    },
    // 分页切换
    handleCurrent(page) {
      this.pagination.page = page;
      if (this.value != "") {
        let { page, size } = this.pagination;
        this.$get(`/gate/page/${page}/${size}`, {
          videoName: this.value
        }).then(res => {
          this.tableData = res.data.list;
          this.pagination.total = res.data.total;
        });
      } else {
        this.getList();
      }
    },
    // 删除
    handleDelete(row) {
      this.$confirm("此操作将永久删除该闸门，确定删除?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          this.$post(`/gate/deleteById/${row.id}`)
            .then(res => {
              if (res.code == 200) {
                this.$message({
                  message: res.message,
                  type: "success"
                });
                if (this.pagination.total % 10 == 1) {
                  this.pagination.page--;
                }
              } else {
                this.$message({
                  message: res.message,
                  type: "error"
                });
              }
            })
            .catch(err => {
              console.log(err);
            })
            .finally(() => {
              this.getList();
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除"
          });
        });
    },
    // 新建 编辑
    handleGate(type, row) {
      this.addShow = true;
      this.gateInfo = {};
      if (type == "add") {
        this.type = false;
        this.title = "闸门档案管理-新增";
      } else {
        this.title = "闸门档案管理-编辑";
        this.type = true;
        this.gateInfo = JSON.parse(JSON.stringify(row));
      }
    },
    // 保存
    handleSave(formName) {
      // 编辑
      if (this.type) {
        let {
          id,
          siteId,
          deviceAddress,
          gateName,
          gateHeightLimit
        } = this.gateInfo;
        siteId = siteId.toString();
        this.$refs[formName].validate(valid => {
          if (valid) {
            gateUpdate({
              id,
              siteId,
              deviceAddress,
              gateName,
              gateHeightLimit
            })
              .then(res => {
                if (res.code == 200) {
                  this.$message({
                    message: res.message,
                    type: "success"
                  });
                  this.addShow = false;
                } else {
                  this.$message({
                    message: res.message,
                    type: "error"
                  });
                }
              })
              .catch(err => {
                this.$message({
                  message: err.message,
                  type: "error"
                });
              })
              .finally(() => {
                this.getList();
              });
          } else {
            return false;
          }
        });
      } else {
        // 新增
        this.$refs[formName].validate(valid => {
          if (valid) {
            let {
              id,
              siteId,
              deviceAddress,
              gateName,
              gateHeightLimit
            } = this.gateInfo;
            siteId = siteId.toString();
            gateAdd({
              id,
              siteId,
              deviceAddress,
              gateName,
              gateHeightLimit
            })
              .then(res => {
                if (res.code == 200) {
                  this.$message({
                    message: res.message,
                    type: "success"
                  });
                  this.gateInfo = {};
                  this.addShow = false;
                }
              })
              .catch(err => {
                this.$message({
                  message: err.message,
                  type: "error"
                });
              })
              .finally(() => {
                this.pagination.page = 1;
                this.getList();
              });
          } else {
            return false;
          }
        });
      }
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
      this.addShow = false;
    },
    closeBox() {
      this.$refs["ruleForm"].resetFields();
    },
    // 站点名称变化
    changeValue(value) {
      this.stationList.forEach(item => {
        if (value == item.value) {
          this.gateInfo.deviceAddress = item.deviceAddress;
        }
      });
    }
  },
  created() {
    this.getList();
  },
  mounted() {}
};
</script>
<style lang="scss" scoped>
::v-deep .el-dialog {
  .el-icon-circle-close {
    display: none !important;
  }
  .el-input,
  .el-textarea,
  .el-select {
    width: calc(244 / 1920 * 100vw);
  }
  .images {
    height: calc(60 / 1080 * 100vh);
    i {
      width: calc(59 / 1920 * 100vw);
      height: calc(59 / 1080 * 100vh);
      border: 1px solid #585858;
      font-size: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
.label_r {
  color: #666;
  text-align: left;
  width: calc(80 / 1920 * 100vw);
  // margin-bottom: 10px;
}
::v-deep .el-form-item__content {
  display: flex;
  justify-content: center;
  margin: 0 auto;
  align-items: flex-start;
}
.contentAdd {
  padding-top: 10px;
}
::v-deep .el-input.search_input {
  width: calc(175 / 1920 * 100vw);
}

::v-deep .menu_left {
  .el-input__inner {
    width: calc(200 / 1920 * 100vw);
  }
}
::v-deep .el-icon-circle-check:before {
  content: "" !important;
}

::v-deep .el-date-editor.el-range-editor.el-input__inner {
  width: calc(265 / 1920 * 100vw) !important ;
}
::v-deep .el-input__icon {
  font-size: calc(10 / 1920 * 100vw) !important;
}

::v-deep .el-dialog {
  background: #4073b6;
  border-radius: 16px;
  width: calc(388 / 1920 * 100vw) !important;
  .el-dialog__header {
    background: #fff;
    margin-top: 4px;
    border-radius: 16px 16px 0 0;
  }
  .el-dialog__footer {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #fff;
    border-radius: 0 0 16px 16px;
    padding: calc(15 / 1080 * 100vh) 0;
     .el-button:nth-child(1):hover {
      background: #eaeaea;
      border: 1px #eaeaea solid;
      color: #313131;
    }
  }
  // 弹框主体
  .el-dialog__body {
    background: #fff;
    height: calc(395 / 1080 * 100vh);
    // 菜单选框
    .el-checkbox-group {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      label {
        width: 34% !important;
        height: calc(25 / 1080 * 100vh);
        margin-right: 0;
      }
    }
  }
  .el-input__inner {
    border: none;
    border-radius: 4px;
    background: #f3f4f8;
  }
}
</style>
